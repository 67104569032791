<template>

  <el-dialog :title="this.title" width="800px" v-model="editDigShow" :close-on-click-modal="false">

    <div class="pageHeader" style="overflow: hidden; margin-bottom: 10px;">
      <div style="float:left">
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
      </div>
      <div style="float:right;">
        <el-input placeholder="客户名称" v-model="customerName" class="input-with-select" style="width:350px;"></el-input>&nbsp;
        <el-button type="primary" @click="handle_search()" icon="el-icon-search">搜索</el-button>
      </div>
    </div>

    <el-table
        ref="singleTable"
        :data="page.list"
        highlight-current-row
        @current-change="handleCurrentChange"
        style="width: 100%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="name" label="客户名称" min-width="300"></el-table-column>
      <el-table-column prop="tag" label="标签" width="200">
        <template  #default="scope">
          <el-tag style="margin-left: 5px;"
                  v-for="(tag, index) in customerTagInfo[scope.row.id]"
                  :key="tag.name">
            {{tag.name}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="level" label="级别" width="100"></el-table-column>
    </el-table>
    <el-pagination @current-change="devlogChange"
                   :current-page="page.pageNum"
                   :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                   :total="page.total">
    </el-pagination>

    <div slot="footer" class="dialog-footer" style="text-align:right; margin-top: 10px;">
      <el-button @click="handle_cancel()">关闭</el-button>
      <el-button type="primary" @click="handle_save('proInfo')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import CUS from '../../api/biz/api_customer';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";

export default {
  name: "customerselectdig", // 客户选择列表
  components: {
  },
  props: {
    editDigShow:{
      type: Boolean
    },
    customerName: {
      type: String
    }
  },
  data() {
    return {
      showLoading: false,
      page: {list: [], pageNum: 1, pageSize: 6, total: 0},
      currentRow: null,
      title: '选择客户',
      customerTagList: [],
      customerTagInfo: {}
    }
  },
  // 初始化加载
  mounted() {
      this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.init_base();
      this.initPageData();
    },

    // 加载初始数据支持
    init_base(){
      COMM.getObjType({type: 'customertag'}).then((res) => {
        this.customerTagList = res;
      });
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;
      let did = this.$store.state.cuttuser.deptId, nodeType = 0;

      CUS.list({
        // did: 0,
        // nodeType: 0,//节点的类型
        // status: 3, // 待验证项目
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        keyword: this.customerName
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;

          // 构造客户标签
          this.buildCustomerTags(data.list);
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },

    buildCustomerTags(customerList) {
      if (customerList == null || customerList.length == 0) {
        return;
      }

      for (const index in customerList) {
        let customer = customerList[index];
        let customerId = customer['id'];

        let customerCheckedTags = [];
        let tag = customer.tag||'';
        for (const index in this.customerTagList) {
          let customerTag = this.customerTagList[index];
          if (tag.indexOf(customerTag['value'] + ',') >= 0) {
            customerCheckedTags.push(customerTag);
          }
        }

        this.customerTagInfo[customerId] = customerCheckedTags;
      }
    },

    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    // 查询
    handle_search() {
      this.initPageData();
    },

    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },

    handleCurrentChange(val) {
      console.log(val);
      this.currentRow = val;
    },

    // 确定
    handle_save() {
      console.log(this.currentRow);
      this.$parent.customerId = this.currentRow.id;
      this.$parent.customerInfo = this.currentRow;
      this.$parent.customerselectShow=false;
    },

    // 取消
    handle_cancel() {
      this.$parent.customerselectShow=false;
    },
  }
}
</script>