/**
 * Created by xiongxiaomeng.
 * 验证计划相关api
 */
import * as API from '../../utils/request';

export default {
    //管理
    info: params => {  return API.POST('verifyplan/info', params)},
    infodetails: params => {  return API.POST('verifyplan/infodetails', params)},
    list: params => {  return API.GET('verifyplan/list', params)},
    get: params => {  return API.GET('verifyplan/get', params)},
    delete: params => { return API.DELETE('verifyplan/delete', params) },
}
