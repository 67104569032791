<template>
  <div class="form">
    <el-row :gutter="20">
      <el-col :span="3"><label>客户名称</label></el-col>
      <el-col :span="15">{{ customerInfo?.name }} </el-col>
      <el-col :span="4">
        <el-button v-if="customerselectBtnShow" type="text" icon="el-icon-plus" @click="handler_selectcustomer">选择客户</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="3"><label>年度验证计划</label></el-col>
      <el-col :span="21"
        ><el-select
          v-model="verifyPlanId"
          filterable
          clearable
          @change="handler_changeverifyplanid"
          placeholder="请选择年度验证计划"
          style="width: 500px"
        >
          <el-option value="">请选择年度验证计划</el-option>
          <el-option
            v-for="item in verifyPlanList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-col>
    </el-row>

    <div class="filters">
      <div class="filter">
        <label>改造标记</label>
        <el-switch
          v-model="rebuildflag"
          active-text="开"
          width="60"
          active-color="#13ce66"
          active-value="true"
          @change="handler_changeflag"
          inactive-value="false"
        >
        </el-switch>
      </div>
      <div class="filter">
        <label>损坏标记</label>
        <el-switch
          v-model="breakflag"
          width="60"
          active-color="#13ce66"
          active-text="开"
          active-value="true"
          @change="handler_changeflag"
          inactive-value="false"
        >
        </el-switch>
      </div>
    </div>
    <el-table
      class="projects-box"
      :data="resultProjectList"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      border
      width="width:100%; height:50%"
    >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="custid" label="用户" width="150"></el-table-column>
      <el-table-column
        prop="reportno"
        label="编号"
        width="200"
      ></el-table-column>
      <el-table-column prop="name" label="项目名称"></el-table-column>
      <el-table-column
        prop="rebuildflag"
        label="改造标记"
        width="100"
        :formatter="formatterRebuildflag"
      ></el-table-column>
      <el-table-column
        prop="breakflag"
        label="损坏标记"
        width="100"
        :formatter="formatterBreakflag"
      ></el-table-column>
      <el-table-column prop="scope" label="操作" width="100">
        <template #default="scope">
          <el-button
            type="text"
            icon="el-icon-remove"
            @click="handler_deleteproject(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="project-box">
      <el-row>
        <el-col :span="18">
          <el-form
            :model="editProjectInfo"
            ref="editProjectInfo"
            :rules="editProjectInfoRules"
          >
            <el-form-item
              label="输入项目名称"
              label-width="200px"
              prop="projectId"
            >
              <el-select
                v-model="editProjectInfo.projectId"
                filterable
                clearable
                style="width: 500px"
                placeholder="请输入项目名称"
              >
                <el-option
                  v-for="item in resultUnselectProjectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4">
          <el-button type="text" icon="el-icon-plus" @click="handler_addproject"
            >添加项目
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="buttons">
      <el-button type="primary" icon="el-icon-view" v-if="auth['order:save']" @click="handler_saveorder"
        >保存订单
      </el-button>
    </div>

    <CustomerSelectDig :editDigShow="customerselectShow"></CustomerSelectDig>
  </div>
</template>

<script>
import ORDER from "../../api/biz/api_order";
import VERIFY from "../../api/biz/api_verifyplan";
import CUST from "../../api/biz/api_customer";
import PROJECT from "../../api/base/api_pm";

import BreakflagStatus from "./breakflag_status";
import RebuildingflagStatus from "./rebuildingflag_status";

import CustomerSelectDig from "../customer/listSelectDialog";

export default {
  name: "createorder",
  props: {

  },
  components: {
    CustomerSelectDig
  },
  data() {
    return {
      orderId: undefined,
      verifyPlanList: [],
      //
      customerId: undefined,
      verifyPlanId: undefined,
      customerInfo: undefined,

      tests: [],

      //filter
      rebuildflag: "false",
      breakflag: "false",

      //list project by verifyPlanId
      projectList: [],
      //list by customerId and not use by verifyPlanId
      unselectProjectList: [],
      //show in list view
      resultProjectList: [],
      //show in select view
      resultUnselectProjectList: [],

      editProjectInfo: { projectId: undefined },
      editProjectInfoRules: {
        projectId: [
          { required: true, message: "请选择项目", trigger: "change" },
        ],
      },

      customerselectShow: false,
      customerselectBtnShow: false,
      auth:{},
    };
  },
  watch:{
    customerId:function(newValue,oldValue){
      if(!oldValue&&newValue){
        this.init()
      }

    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.initPageData();
      this.auth=this.$getAuth();
    },
    initPageData() {
      // this.customerId = this.$route.params.customerid;
      if(!this.customerId){
        this.customerId = this.$route.query?.customerId;
      }
      if (!this.customerId) {
        //this.$message.error("没有找到客户");
        this.customerselectBtnShow = true;
        return;
      }
      let params1 = { id: this.customerId };

      CUST.get(params1).then((res1) => {
        if (200 == res1.code) {
          if (res1?.data) {
            this.customerInfo = res1?.data;
            let params2 = {
              custid: this.customerId,
              pageSize:100000000,
              pageNum:1,
            };
            VERIFY.list(params2).then((res2) => {
              if (200 == res2.code) {
                if (res2?.pageData) {
                  this.verifyPlanList = res2.pageData.list;
                }
              } else {
                this.$message.error(res2?.msg);
              }
            });
            this.initProjectList();
          } else {
            this.$message.error("没有找到客户,不能新建订单");
            this.customerInfo = undefined;
          }
        } else {
          this.$message.error(res1?.msg);
        }
      });
    },
    initProjectList() {
      if (this.verifyPlanId) {
        let params1 = {
          verifyPlanId: this.verifyPlanId,
        };
        this.showLoading = true;
        PROJECT.listByVerifyPlanId(params1)
          .then((res) => {
            if (200 == res.code) {
              this.showLoading = false;
              if (res?.data) {
                this.projectList = res.data;
              }
            }
          })
          .catch((e) => console.error("promise catch err", e));
        let params2 = {
          customerId: this.customerId,
          verifyPlanId: this.verifyPlanId,
        };
        PROJECT.listByCustomerIdAndNotVerifyPlanId(params2)
          .then((res) => {
            if (200 == res.code) {
              if (res?.data) {
                this.unselectProjectList = res.data;
                this.filterProjectList();
              }
            }
          })
          .catch((e) => console.error("promise catch err", e));
      } else if (this.customerId) {
        let params = {
          custid: this.customerId,
          pageNum: 1,
          pageSize: 100000000,
        };
        PROJECT.list(params)
          .then((res) => {
            if (200 == res.code) {
              if (res?.pageData?.list) {
                this.unselectProjectList = res?.pageData?.list;
                this.filterProjectList();
              }
            }
          })
          .catch((e) => console.error("promise catch err", e));
      }
    },

    handler_changeverifyplanid() {
      if (this.customerId || this.verifyPlanId) {
        this.initProjectList();
      }
      this.resultProjectList = [];
      this.resultProjectList.push({ name: "" });
    },
    handler_changeflag() {
      this.filterProjectList();
    },
    handler_addproject() {
      this.$refs["editProjectInfo"].validate((valid) => {
        if (valid) {
          let projectId = this.editProjectInfo?.projectId;
          if (projectId) {
            let index1 = this.unselectProjectList.findIndex(
              (p) => p.id === projectId
            );
            let project = this.unselectProjectList.splice(index1, 1);

            this.projectList.push(...project);
            this.editProjectInfo.projectId = "";
            this.$refs["editProjectInfo"].resetFields();
            this.filterProjectList();
          }
        }
      });
    },
    handler_deleteproject(row) {
      if (row?.id) {
        let id = row?.id;
        let result = this.resultProjectList.filter((p) => p.id === id);
        if (result) {
          let index = this.projectList.findIndex((p) => p.id === id);
          if (index > -1) {
            this.projectList.splice(index, 1);
          }
          this.unselectProjectList.push(...result);
          this.filterProjectList();
        }
      }
    },
    handler_saveorder() {
      if (!this.customerId) {
        this.$message.error("没有选择客户");
        return false;
      }
      if (!this.resultProjectList || this.resultProjectList.length == 0) {
        this.$message.error("没有选择项目");
        return false;
      }
      let ids = this.resultProjectList.map((p) => p.id);
      let params = {
        projectids: ids.join(","),
        customerId: this.customerId,
        verifyPlanId: this.verifyPlanId,
      };
      ORDER.createByCustomerIdAndProjectIds(params)
        .then((res) => {
          if (200 == res.code) {
            this.$message.success("操作成功");
            this.$router.push({
              path: "orderList",
            });
          } else {
            this.$message.error(res?.msg);
          }
        })
        .catch((err) => console.error("promise catch err", err));
    },

    filterProjectList() {
      if (this.rebuildflag == "true" || this.breakflag == "true") {
        if (this.resultUnselectProjectList) {
          try {
            let that = this.resultUnselectProjectList;
            let result = that.filter((project) => {
              if (this.rebuildflag == "true" && this.breakflag == "true") {
                return project.rebuildflag == 1 && project.breakflag == 1;
              } else if (this.rebuildflag == "true") {
                return project.rebuildflag == 1;
              } else if (this.breakflag == "true") {
                return project.breakflag == 1;
              } else {
                return true;
              }
            });
            this.resultUnselectProjectList = result;
          } catch (error) {
            console.error("error2", error);
          }
        }

        if (this.resultProjectList) {
          try {
            let that = this.resultProjectList;
            let result = this.resultProjectList.filter((project) => {
              if (this.rebuildflag == "true" && this.breakflag == "true") {
                return project.rebuildflag == 1 && project.breakflag == 1;
              } else if (this.rebuildflag == "true") {
                return project.rebuildflag == 1;
              } else if (this.breakflag == "true") {
                return project.breakflag == 1;
              } else {
                return true;
              }
            });

            this.resultProjectList = result;
          } catch (error) {
            console.error("error1", error);
          }
        }
      } else {
        this.resultUnselectProjectList = this.unselectProjectList.concat();
        this.resultProjectList = this.projectList.concat();
      }

    },

    formatterBreakflag(row, index) {
      let val = row?.breakflag;
      return BreakflagStatus.getLabelByValue(val);
    },
    formatterRebuildflag(row, index) {
      let val = row?.rebuildflag;
      return RebuildingflagStatus.getLabelByValue(val);
    },

    handler_selectcustomer() {
      this.customerselectShow = true;
    }
  },
};
</script>

<style scoped>
.form {
  margin: 10px;
}
.el-row {
  margin-bottom: 10px;
}
.el-col label {
  float: right;
}
.filters {
  line-height: 50px;
  text-align: right;
}
.filter {
  display: inline;
  margin-right: 30px;
}
.filter label {
  margin-right: 10px;
}
.projects-box {
  margin-bottom: 10px;
}
.project-box {
  margin-bottom: 10px;
}
.buttons {
  margin: 0 20px;
}
</style>